<template>
  <div>
    <div>
      <!-- <div class="small-top-nav">
        <div class="top-nav">
          <p>{{ currentPath }}</p>
          <p v-if="firstname != '' && lastname != ''">Hello {{ firstname }}</p>
        </div>
      </div> -->
      <SmallNav />

      <div class="wallet">
        <div class="container">
          <div class="wallet_text_holder">
            <h4 class="your_wallet">Your Wallet</h4>
            <div class="link_hook">
              <h6>
                <img src="../assets/images/link-hook.svg" alt="link" /> Link
                Smart Wallet
              </h6>
            </div>
          </div>
          <div class="row row_holder">
            <div class="col-md-6 col-sm-12">
              <div class="wallet_div">
                <img src="../assets/images/card.svg" alt="card" />
                <div class="fund_btn">
                  <button class="btn" data-toggle="modal" data-target="#fundme">
                    Fund
                    <img src="../assets/images/wallet-small.svg" alt="fund" />
                  </button>
                </div>
                <div class="wallet_amount" v-if="wallet.balance">
                  <!-- Number(property.price).toLocaleString() -->
                  <p>&#8358;{{ Number(wallet.balance).toLocaleString() }}</p>
                  <img src="../assets/images/wallet.svg" alt="" />
                </div>
                <h5 class="balance_text">Wallet balance</h5>
              </div>
            </div>
            <div class="col-md-6 col-sm-12" v-if="logger === 'Tenant'">
              <div class="security_deposit">
                <div>
                  <p>Security deposit</p>
                  <img src="../assets/images/info-dot.svg" alt="info" />
                </div>
                <h4 v-if="wallet.securitydeposit">
                  &#8358;{{ Number(wallet.securitydeposit).toLocaleString() }}
                </h4>
                <div class="widthdraw">
                  <button
                    class="btn"
                    data-toggle="modal"
                    data-target="#bank_option"
                  >
                    Widthdraw
                  </button>
                  <h6>To be refunded at the expiration of your stay.</h6>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-12 d-none">
              <div class="fund">
                <div class="">
                  <div class="fund_wallet">
                    <p>Fund Wallet</p>
                    <img src="../assets/images/wallet-b.svg" alt="wallet" />
                  </div>
                  <div class="fund_type">
                    <button class="btn btn-block bank">
                      <img
                        src="../assets/images/double-arrow.svg"
                        alt="transfer"
                      />
                      Bank Transfer
                    </button>
                  </div>

                  <div class="fund_type">
                    <button class="btn btn-block paystack">
                      <img
                        src="../assets/images/block.svg"
                        alt="transfer"
                      />Paystack
                    </button>
                  </div>
                  <div class="fund_type">
                    <button class="btn btn-block flutter">
                      <img
                        src="../assets/images/flutter.svg"
                        alt="transfer"
                      />Flutterwave
                    </button>
                  </div>
                  <div class="fund_type">
                    <button class="btn btn-block enaira">
                      <img
                        src="../assets/images/e-naira.svg"
                        alt="transfer"
                      />e-Naira
                    </button>
                  </div>
                  <!-- <hr> -->
                </div>
              </div>
            </div>
          </div>

          <div class="recent_transactions">
            <h5>Recent transactions</h5>
          </div>

          <div class="pb-4">
            <!-- <div class="transaction_holder">
              <div>
                <p>+</p>
                <p>Credit: Rent payment</p>
                <p>05-Jan-2022</p>
                <h6>&#8358;0,000,000</h6>
                <p>I</p>
              </div>
            </div> -->
            <!-- dataGet -->
            <div
              @click="openPanel(index)"
              class="transaction_holder"
              v-for="(data, index) in transactions"
              :key="index"
            >
              <div class="collapsible">
                <!-- <p>+</p> -->
                <p>
                  {{ data.type }}:
                  <span class="data_type">{{ data.description }}</span>
                </p>
                <p>{{ data.created_at | moment }}</p>
                <h6>&#8358;{{ data.amount }}</h6>
                <!-- <p>{{ data.name }}</p>
                <p>{{ data.date }}</p>
                <h6>&#8358;{{ data.amount }}</h6> -->
                <!-- <p>I</p> -->
              </div>
              <div :id="index" class="content">
                <h6>
                  {{ data.subtype }}
                </h6>
                <!-- <h6>
                  {{ data.content }}
                </h6> -->
              </div>
            </div>
          </div>

          <!-- Modal for successful bank withdrawal begins -->
          <div
            class="modal fade"
            id="withdraw_success"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="close_bank">
                  <h6>Withdraw to bank account</h6>
                  <small data-dismiss="modal" aria-label="Close">x</small>
                </div>
                <div class="modal-body">
                  <div class="bank_widthraw_successful">
                    <div class="withdraw_image_wallet">
                      <img
                        src="../assets/images/double-arrow-blue.svg"
                        alt="arrow"
                      />
                      <img
                        src="../assets/images/wallet-blue.svg"
                        alt="wallet"
                      />
                    </div>
                    <div class="withdrawal_text_modal">
                      <p>
                        A withdrawal of #2,320,000.22 has been initiated. Please
                        wait while we complete your withdrawal process.
                      </p>
                    </div>
                    <div class="withdraw_footer">
                      <img src="../assets/images/blue-tic.svg" alt="good" />
                      <p>.... We Got You Covered !</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal for successful bank withdrawal -->

          <!-- Modal for account details to be withdrawn from -->
          <div
            class="modal fade"
            id="bank_option"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="close_bank">
                  <h6>Withdraw to bank account</h6>
                  <small data-dismiss="modal" aria-label="Close">x</small>
                </div>
                <div class="input_side">
                  <form>
                    <div class="form-group">
                      <label class="select-label input_label" for="bank_name"
                        >Bankk name</label
                      >
                      <select
                        class="form-control bank_input"
                        name="bank_name"
                        v-model="bankname"
                      >
                        <option value="" selected disabled>--select--</option>
                        <option
                          :value="bank"
                          v-for="(bank, index) in banks"
                          :key="index"
                          >{{ bank }}</option
                        >
                      </select>
                    </div>
                    <div class="form-group">
                      <label class="input_label" for="account_name"
                        >Account name</label
                      >
                      <input
                        type="text"
                        class="form-control bank_input"
                        id="account_name"
                      />
                    </div>
                    <div class="form-group">
                      <label class="input_label" for="account"
                        >Account number</label
                      >
                      <input
                        type="number"
                        class="form-control bank_input"
                        id="account"
                      />
                    </div>

                    <div class="form-group">
                      <label class="input_label" for="amount"
                        >Amount(&#8358;)</label
                      >
                      <input
                        type="number"
                        class="form-control bank_input"
                        id="amount"
                      />
                    </div>

                    <div class="form-group send_btn">
                      <button
                        class="btn btn-block"
                        @click.prevent="bankTransferWithdrawal()"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal for account deatils to be wthdrawn from end -->

          <!-- Modal to validate bank transfer with OTP begins -->
          <div
            class="modal fade"
            id="agree"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="close_bank">
                  <h6>Withdraw to bank account</h6>
                  <small data-dismiss="modal" aria-label="Close">x</small>
                </div>
                <div class="modal-body">
                  <p class="modal_veri_text">
                    A verification code has been sent to your registered email.
                  </p>
                  <h6 class="enter_code_text">Kindly enter the code here</h6>
                  <!-- <h5 class="otp__text">{{OTP}}</h5> -->
                  <div class="otp-holder">
                    <input
                      @keyup="keytab($event)"
                      v-model="one"
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      :maxlength="maxLength"
                    />
                    <input
                      @keyup="keytab($event)"
                      v-model="two"
                      type="number"
                      class="form-control"
                      id="exampleInputEmail12"
                      maxlength="1"
                    />
                    <input
                      @keyup="keytab($event)"
                      v-model="three"
                      type="number"
                      class="form-control"
                      id="exampleInputEmail13"
                      maxlength="1"
                    />
                    <input
                      @keyup="keytab($event)"
                      v-model="four"
                      type="number"
                      class="form-control"
                      id="exampleInputEmail14"
                      maxlength="1"
                    />
                  </div>

                  <div class="otp__btn">
                    <button
                      class="btn"
                      @click="
                        concatOtp();
                        bankTransferOTP();
                      "
                    >
                      Confirm Withdrawal
                      <i
                        class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                        v-if="loader"
                      ></i>
                    </button>
                    <p>Didn’t get the code? <span>Resend Code</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal to validate bank transfer WITH OTP ends -->

          <!-- Modal to fund wallet begins -->
          <div
            class="modal fade"
            id="fundme"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="close_bank">
                  <h6>Fund Wallet</h6>
                  <small data-dismiss="modal" aria-label="Close">x</small>
                </div>
                <div class="modal-body">
                  <div class="funds_wrap">
                    <h4 class="fund_title">Select your prefered channel</h4>
                    <div class="funds_options">
                      <div class="fund_type">
                        <button
                          class="btn btn-block bank"
                          @click="fundFromBank()"
                        >
                          <img
                            src="../assets/images/double-arrow.svg"
                            alt="transfer"
                          />
                          Bank Transfer
                        </button>
                      </div>

                      <div class="fund_type">
                        <button class="btn btn-block paystack" disabled>
                          <img
                            src="../assets/images/block.svg"
                            alt="transfer"
                          />Paystack
                        </button>
                      </div>
                      <div class="fund_type">
                        <button class="btn btn-block flutter" disabled>
                          <img
                            src="../assets/images/flutter.svg"
                            alt="transfer"
                          />Flutterwave
                        </button>
                      </div>
                      <div class="fund_type">
                        <button class="btn btn-block enaira" disabled>
                          <img
                            src="../assets/images/e-naira.svg"
                            alt="transfer"
                          />e-Naira
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal to Fund wallet -->

          <!-- Modal for bank statment upload begins -->
          <div
            class="modal fade"
            id="showpayment"
            tabindex="-1"
            role="dialog"
            aria-labelledby="showpayment"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header payment_header_holder">
                  <img
                    v-if="headerText2"
                    @click="switchView(0)"
                    class="back__arrow"
                    src="../assets/images/arrow2-white.svg"
                    alt=""
                  />
                  <img
                    v-if="headerText3"
                    @click="switchView(1)"
                    class="back__arrow"
                    src="../assets/images/arrow2-white.svg"
                    alt=""
                  />
                  <h5
                    v-if="headerText1"
                    class="modal-title payment-header"
                    id="exampleModalLongTitle"
                  >
                    Pay My Rent
                  </h5>
                  <h5
                    v-if="headerText2"
                    class="modal-title payment-header"
                    id="exampleModalLongTitle"
                  >
                    Proof of payment upload.
                  </h5>
                  <h5
                    v-if="headerText3"
                    class="modal-title payment-header"
                    id="exampleModalLongTitle"
                  >
                    Call Shelta.
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" class="close_times">&times;</span>
                  </button>
                </div>
                <div id="onee" class="modal-body">
                  <div class="">
                    <div class="dot-holder">
                      <span class="step active"></span>
                      <span class="step"></span>
                      <span class="step"></span>
                      <!-- <span class="step"></span> -->
                    </div>
                    <div class="double_arrow_holder">
                      <img
                        src="../assets/images/double-arrow-blue.svg"
                        alt="back"
                      />
                      <p>Bank Transfer</p>
                    </div>
                    <div class="form-outter">
                      <div class="first-step">
                        <div class="number__holder">
                          <p class="span-count">1</p>
                          <p>
                            Transfer the amount due to the acount number below
                          </p>
                        </div>

                        <div class="sheltaAccount__details">
                          <div>
                            <p>Account Name:</p>
                            <p>Shelta Panacea Limited</p>
                          </div>
                          <div>
                            <p>Account Number :</p>
                            <p>0708490113</p>
                          </div>
                          <div>
                            <p>Bank :</p>
                            <p>Guarantee Trust Bank</p>
                          </div>
                        </div>
                        <div class="number__holder second__number">
                          <p class="span-count">2</p>
                          <p>
                            After the transfer is completed, please keep your
                            payment receipt by screenshot or photo and upload it
                            in the next step.
                          </p>
                        </div>

                        <div class="desclaimer__text">
                          <p>
                            Kindly note that your rent starts running 48 hours
                            after your payment is approved
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-foot">
                    <button
                      @click="switchView(1)"
                      type="button"
                      class="btn next-btn btn-block"
                    >
                      Next
                    </button>
                  </div>
                </div>

                <div id="twoo" class="modal-body">
                  <div class="container-fluid">
                    <div class="dot-holder">
                      <span class="step"></span>
                      <span class="step active"></span>
                      <span class="step"></span>
                    </div>
                    <div class="form-outter">
                      <div class="second-step">
                        <p class="info__text">
                          Please upload the payment receipt so that we can find
                          your payment.
                        </p>
                        <div class="dateOf__payment">
                          <label for="">Date of payment <span>*</span></label>
                          <input
                            class="form-control"
                            name="date"
                            type="date"
                            placeholder="Select date of payment"
                          />
                        </div>

                        <div class="upload__file">
                          <div class="dropzone">
                            <input
                              @change="selectFile($event)"
                              class="input-file"
                              ref="file"
                              type="file"
                            />
                            <img
                              src="../assets/images/file-upload.svg"
                              alt=""
                            />
                            <img
                              v-if="fileselected"
                              class="actual_image"
                              :src="filePath"
                              alt=""
                            />
                            <p
                              v-if="!uploading && !fileselected"
                              class="call-to-action"
                            >
                              <!-- <p v-if="!uploading" class="call-to-action"> -->
                              Drag and drop or
                              <span class="browse__span">browse</span> your
                              files
                            </p>
                            <p
                              v-if="uploading"
                              class="call-to-action"
                              style="color: green;"
                            >
                              Uploding your reciept...
                            </p>
                            <!-- <p v-if="uploading" class="progress-bar">
                        <progress
                          class="progress is-primary"
                          :value="progress"
                          max="100"
                          >{{ progress }} %</progress
                        >
                      </p> -->
                          </div>
                          <p
                            v-if="uploading"
                            class="call-to-action"
                            style="color: green;"
                          >
                            Uploding your reciept...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-foot">
                    <!-- switchView(2) -->
                    <button
                      @click="sendFile"
                      disabled
                      type="button"
                      class="btn next-btn btn-block"
                    >
                      Submit
                    </button>
                  </div>
                </div>

                <div id="threee" class="modal-body">
                  <div class="container-fluid">
                    <div class="dot-holder">
                      <span class="step"></span>
                      <span class="step"></span>
                      <span class="step active"></span>
                    </div>
                    <div class="form-outter">
                      <div class="third-step">
                        <img src="../assets/images/rent-call.svg" alt="" />
                        <p>
                          Submited successfully ! We will confirm your payment
                          and update your status within 48 hours. For enquiries
                          please call our customer service agent on :
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="modal-foot">
                    <button type="button" class="btn call-btn">
                      <a href="tel:2348061796909">
                        <img src="../assets/images/phone-conect.svg" alt="" />
                        08061796909
                      </a>
                    </button>
                  </div>
                </div>

                <!-- <div class="modal-footer modal-foot">
            
            <button type="button" class="btn next-btn btn-block">Next</button>
          </div> -->
              </div>
            </div>
          </div>
          <!-- Modal for bank statment upload ends -->

          <!-- <div class="d-none">
            <button class="collapsible">Open Section 1</button>
            <div class="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <button class="collapsible">Open Section 2</button>
            <div class="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <button class="collapsible">Open Section 3</button>
            <div class="content">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
          </div> -->

          <!-- <div>
            <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
              Button with data-target1
            </button>
          </div>
          <div class="collapse" id="collapseExample">
            <div class="card card-body">
              Amingo supper market done open for Keffi pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
            </div>
          </div>

          <div>
            <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample">
              Button with data-target2
            </button>
          </div>
          <div class="collapse" id="collapseExample2">
            <div class="card card-body">
              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
            </div>
          </div> -->
        </div>
      </div>
      <!-- <Footer /> -->
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
// import { defineComponent } from '@vue/composition-api'
import Footer from "../views/FooterMain2.vue";
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";
import moment from "moment";

export default {
  name: "Wallet",
  components: {
    SmallNav,
    Footer
  },
  data() {
    return {
      maxLength: 1,
      id: "",
      user: "",
      firstname: "",
      lastname: "",
      currentPath: "Overview",
      one: "",
      two: "",
      three: "",
      four: "",
      loader: "",
      wallet: "",
      panel: 0,
      error: false,
      uploading: false,
      fileselected: false,
      filePath: "",
      progress: 0,
      file: "",
      headerText1: true,
      headerText2: false,
      headerText3: false,
      userid: "",
      percentageFull: false,
      transactions: [],
      logger: "",
      banks: [],
      // bank: "",
      bankname: "",
      dataGet: [
        {
          name: "Credit: rent payment",
          date: "22/3/2022",
          amount: "1200000",
          content: "Loading...."
        },
        {
          name: "Debit: rent payment2",
          date: "22/2/2022",
          amount: "1300000",
          content: "Loading2..."
        },
        {
          name: "Credit: rent payment3",
          date: "22/1/2022",
          amount: "1100000",
          content: "Loading3..."
        }
      ]
    };
  },
  mounted() {
    this.logger = localStorage.getItem("shelta-loger");
    this.firstname = JSON.parse(localStorage.getItem("shelta-user")).firstname;
    this.lastname = JSON.parse(localStorage.getItem("shelta-user")).lastname;
    this.currentPath = this.$route.path;
    if (
      this.currentPath === "/dashboard/landlord" ||
      this.currentPath === "/dashboard/tenant"
    ) {
      this.currentPath = "Overview";
    } else if (this.currentPath === "/dashboard/properties") {
      this.currentPath = "Properties";
    } else {
      this.currentPath = "Wallet";
    }
    this.fetchWalletDetails();
    this.getBankNames();
  },
  methods: {
    fundFromBank: function(hi) {
      $("#fundme")
        .removeClass("fade")
        .modal("hide");
      $("#showpayment")
        .addClass("fade")
        .modal("show");
    },
    async getBankNames() {
      try {
        await Api.getRequest(`getbanks`)
          .then(res => {
            // console.log("Banks", res);
            this.banks = res.data.banks;
          })
          .catch(err => {
            console.log("Error", err);
          });
      } finally {
        console.log("closed connect");
      }
    },
    switchView(event) {
      if (event === 1) {
        document.getElementById("onee").style.display = "none";
        document.getElementById("twoo").style.display = "block";
        document.getElementById("threee").style.display = "none";
        this.headerText1 = false;
        this.headerText2 = true;
        this.headerText3 = false;
      } else if (event === 2) {
        document.getElementById("onee").style.display = "none";
        document.getElementById("twoo").style.display = "none";
        document.getElementById("threee").style.display = "block";
        this.headerText1 = false;
        this.headerText2 = false;
        this.headerText3 = true;
      } else if (event === 0) {
        document.getElementById("onee").style.display = "block";
        document.getElementById("twoo").style.display = "none";
        document.getElementById("threee").style.display = "none";
        // this.headerText2 = false;
      } else {
        document.getElementById("twoo").style.display = "none";
        document.getElementById("onee").style.display = "none";
        document.getElementById("threee").style.display = "block";
      }
    },

    selectFile(event) {
      const file = this.$refs.file.files[0];
      this.file = file;
      this.filePath = URL.createObjectURL(file);
      this.fileName = file.name;
      if (this.file) {
        this.fileselected = true;
      } else {
        this.fileselected = false;
      }
    },
    async sendFile() {
      // this.switchView(2)
      const file = this.$refs.file.files[0];
      // this.file = file;
      if (!this.file) {
        // this.loaderM = false;
        return this.$store.commit("setApiFailed", "No file was selected!");
      }
      const formData = new FormData();
      formData.append("file", this.file);
      // formData.append("modeofpaymentid", this.paymode);
      // console.log("property id", this.paymentData.nextpaymentid);
      // this.$store.commit("setApiWarning", 'Uploading...');
      try {
        this.uploading = true;
        await Api.postRequest(`paynow`, formData, {
          onUploadProgress: e =>
            (this.progress = Math.round((e.loaded * 100) / e.total))
        })
          .then(res => {
            // console.log("Uploaded file", res);
            // this.$store.commit("setApiWarning", '');
            this.$store.commit("setApiSuccess", "Success");
            this.switchView(2);
            this.uploading = false;
            if (res.data.success) {
              this.moreTenantData();
            }
            // this.message = "File has been uploaded";
            // this.file = '';
            // this.error = false;
          })
          .catch(err => {
            console.log(err);
            // this.loader = false;
            this.uploading = true;
          });
      } finally {
        console.log("Closed connection");
      }
    },
    openPanel: function(id) {
      console.log("Index", id);
      this.panel = id;
      const coll = document.getElementsByClassName("collapsible");
      let i;

      for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function() {
          console.log("Index", coll);
          this.classList.toggle("active");
          const content = this.nextElementSibling;
          if (content.style.maxHeight) {
            console.log("Next sibline", content);
            content.style.maxHeight = null;
          } else {
            content.style.maxHeight = content.scrollHeight + "px";
            console.log("Next sibline2", content);
          }
        });
      }
    },
    fetchWalletDetails: async function() {
      try {
        await Api.getRequest(`ewallet`)
          .then(res => {
            // console.log("Wallet", res);
            this.wallet = res.data;
            this.transactions = res.data.wallet;
          })
          .catch(err => {
            console.log(err);
            // this.loader = false;
            // this.uploading = true;
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    bankTransferWithdrawal() {
      $("#bank_option")
        .removeClass("fade")
        .modal("hide");
      $("#agree")
        .addClass("fade")
        .modal("show");

      // if (this.percentageFull === true) {
      //   $("#showpayment").modal("show");
      // } else {
      //   $("#showpayment").modal("hide");
      //   return this.$store.commit(
      //     "setApiFailed",
      //     "Profile not completed"
      //   );
      // }
    },

    bankTransferOTP() {
      $("#agree")
        .removeClass("fade")
        .modal("hide");
      $("#withdraw_success")
        .addClass("fade")
        .modal("show");
    },
    concatOtp() {
      const theOtp = this.one.concat(this.two, this.three, this.four);
      this.token = theOtp;
      console.log("one OTP", theOtp);
      // this.sendOTP();
    },
    keytab(event) {
      const nextInput = event.srcElement.nextElementSibling; // get the sibling element
      const previousInput = event.srcElement.previousElementSibling;

      const target = event.target || event.srcElement;
      // const id = target.id;
      console.log("Key pressed", event.key); // prints undefined
      if (event.key === "Backspace") {
        console.log("back", target);
        // previousInput === null;
      }

      if (nextInput === null) {
        // check the maxLength from here
        console.log("next check", nextInput);
        return;
      } else {
        nextInput.focus();
      } // focus if not null
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

// .small-top-nav {
//   background: $secondary;
// }
// .top-nav {
//   display: flex;
//   justify-content: space-between;
//   padding: 1rem;
//   height: 3.5rem;
//   p {
//     font-family: Gotham;
//     font-style: normal;
//     font-weight: 500;
//     font-size: 15px;
//     line-height: 30px;
//     align-items: center;
//     color: $black;
//   }
// }

.link_hook {
  h6 {
    background: $primary;
    color: $secondary;
    padding: 5px 10px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;

    img {
      padding-right: 5px;
    }
  }
}

.your_wallet {
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: $black;
}

.fund_btn {
  position: absolute;
  top: 10%;
  display: flex;
  padding-left: 20px;
  button {
    border: 0.5px solid $secondary;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    color: $secondary;
    img {
      padding-left: 20px;
    }
  }
  button:hover {
    background: #2fc643;
    border: 0.5px solid $secondary;
  }
}

.fund_title {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: $black;
  border: 1px solid #e5e5e5;
  padding-top: 10px;
  padding-bottom: 15px;
}

.funds_wrap {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  margin: 40px 52px;
  border-radius: 10px;
}

.funds_options {
  margin: 10px 22px;
}

.fund_type2 {
  padding: 5px 15px;
  button {
    height: 40px;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    box-sizing: border-box;
    display: flex;
    padding: 7px 25px;
    align-items: center;
    img {
      padding-right: 10px;
    }
  }
  .bank {
    background: $primary;
  }
  .paystack {
    background: #56c1f2;
  }
  .flutter {
    background: $yellow;
  }
  .enaira {
    background: #16780d;
    margin-bottom: 5px;
  }
}

.wallet {
  margin-top: 20px;
  height: 100%;
  // height: 100vh;
}

.wallet_text_holder {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.wallet_div {
  position: relative;
  // background: url("../assets/images/card.svg");
  // background-size: 100% 100%;
  // background-attachment: fixed;
  // background-repeat: no-repeat;
  // max-height: 248px;
  // height: 100%;
  // background-size: cover;
  //  height: 100vh;
  // img {
  //   width: 100%;
  // }
}
.wallet_div > img {
  width: 100%;
}
.wallet_amount {
  display: flex;
  justify-content: space-between;
  // align-items: end;
  align-items: baseline;
  position: absolute;
  top: 30%;
  // bottom: 20px;
  // right: 20px;
  // background-color: black;
  color: $secondary;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;

  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
  }
}

.balance_text {
  position: absolute;
  bottom: 7%;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  display: flex;

  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  color: $secondary;
}

.security_deposit {
  background: linear-gradient(
    90deg,
    rgba(0, 51, 234, 0.8) 0%,
    rgba(80, 115, 240, 0.8) 233.6%
  );
  border-radius: 10px;
  // max-height: 250px;
  height: 100%;
  h4 {
    display: flex;
    color: $secondary;
    padding: 20px 15px;
  }
}
.security_deposit > div:first-child {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: self-start;
  p {
    color: $secondary;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
  }
}

.widthdraw {
  display: flex;
  justify-content: space-between;
  padding: 30px 15px;
  align-items: center;
  button {
    background: #7996fb;
    color: $secondary;
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 30px;
    padding: 5px 30px;
  }
  h6 {
    text-align: left;
    // padding-left: 45px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    color: $secondary;
    width: 52%;
  }
}

.fund {
  background: $secondary;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
}

.fund_wallet {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px 15px 0;
  border: 1px solid #e5e5e5;
  p {
    margin-bottom: 6px;
  }
}

.fund_type {
  padding: 5px 15px;
  button {
    height: 40px;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    box-sizing: border-box;
    display: flex;
    padding: 7px 25px;
    align-items: center;
    img {
      padding-right: 10px;
    }
  }
  .bank {
    background: $primary;
  }
  .paystack {
    background: #56c1f2;
  }
  .flutter {
    background: $yellow;
  }
  .enaira {
    background: #16780d;
    margin-bottom: 5px;
  }
}

.recent_transactions {
  padding-top: 3rem;
  padding-bottom: 0.5rem;
  h5 {
    font-family: Gotham-bold;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    display: flex;
  }
}

.transaction_holder {
  cursor: pointer;
  background: $secondary;
  height: fit-content;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 10px;
  div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 15px;
    padding: 10px 15px;
    .data_type {
      padding-left: 5px;
      font-weight: 200;
      color: #2e2e2e;
    }
    // p:first-child {
    //   font-weight: bold;
    // }
    p {
      text-align: start;
      font-family: Gotham;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom: 0;
    }
    h6 {
      text-align: start;
      font-family: Gotham-bold;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom: 0;
    }
  }
}

.collapsible {
  // background-color: #777;
  // color: white;
  // cursor: pointer;
  // padding: 18px;
  // width: 100%;
  // border: none;
  // text-align: left;
  // outline: none;
  // font-size: 15px;
}

// .active,
// .collapsible:hover {
//   background-color: #555;
// }

.collapsible:before {
  content: "\002B";
  color: $secondary;
  font-weight: bold;
  float: left;
  // margin-left: 5px;
  background: #2fc643;
  border-radius: 50px;
  height: 24px;
  width: 24px;
}

.active:before {
  content: "\2212";
}
.collapsible:after {
  content: "\02C7";
  font-size: 30px;
  // cotent" "\02C5";
  color: $black;
  font-weight: bold;
  float: left;
  height: 30px;
  // margin-left: 5px;
}

.active:after {
  content: "\02C6";
  //  content: "\02C4";
}

.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.content2 {
  padding: 0 18px !important;
  max-height: 0;
  overflow: hidden !important;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1 !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px;
}

.input_side {
  padding: 15px 60px;
}
.close_bank {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: baseline;
  background: #efefef;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: $black;
  }
  small {
    font-family: Gotham;
    padding-left: 1px;
    text-align: center;
    background: #c4c4c4;
    color: $black;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.input_label {
  display: flex;
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  // line-height: 15px;
  color: $black;
}

.bank_input {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 11px;
  color: #6c6c6c;
  box-sizing: border-box;
  border-radius: 5px;
  height: 45px;
  box-shadow: none;
  outline: 0;
}

.send_btn {
  padding: 20px 0;
  button {
    background: $primary;
    color: $secondary;
    height: 45px;
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 17px;
  }
}

.opt-head {
  border-bottom: none;
}

.modal-body {
  .modal_veri_text {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    margin-bottom: 0;
    color: $black;
  }
  .enter_code_text {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    color: $black;
    padding-bottom: 2rem;
  }
}

.otp-holder {
  display: flex;
  justify-content: space-evenly;
  input {
    width: 80px;
    border: none;
    border-bottom: 2px solid $primary;
    border-radius: 0;
    box-shadow: none;
  }
}

.otp__text {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: #28a745;
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.otp__btn {
  padding: 3rem 10px;
  button {
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    max-width: 377px;
    width: 100%;
    max-height: 45px;
    height: auto;
    box-shadow: none;
    margin-bottom: 2rem;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    text-align: center;
    color: $black;
    span {
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.withdraw_image_wallet {
  margin-top: 50px;
}

.withdrawal_text_modal {
  p {
    padding: 20px 8px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $black;
  }
}

.withdraw_footer {
  padding-bottom: 30px;
  p {
    margin-top: 10px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;
    color: $primary;
  }
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 11px;
  color: #6c6c6c;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 11px;
  color: #6c6c6c;
}

::placeholder {
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 11px;
  color: #6c6c6c;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// Bank transfer upload modal
.payment_header_holder {
  background: $primary;
}

.payment-header {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
  color: $secondary;
}

.close_times {
  color: #ea0000;
  background: $secondary;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 0px 6px;
}

.dot-holder {
  text-align: left;
  margin-top: -10px;
  margin-bottom: 10px;
  display: flex;
}

.double_arrow_holder {
  p {
    margin-top: 5px;
    color: $primary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
  }
}

.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.step.active {
  // opacity: 1;
  background-color: $primary;
}
.step.finish {
  background-color: #04aa6d;
}

#twoo {
  display: none;
}
#threee {
  display: none;
}

//  First step starts here
.number__holder {
  text-align: left;
  display: flex;
  p:first-child {
    background: $primary;
    // padding: 0.3rem 0.69rem;
    border-radius: 50px;
    color: $secondary;
    text-align: center;
    align-items: baseline;
    margin-right: 16px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.5rem;
    line-height: 11px;
    // width: 2rem;
    // height: 1.5rem;
  }
  p:nth-child(2) {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 22px;
    color: $black;
    margin-right: 1rem;
  }
}

.sheltaAccount__details {
  background: rgba(64, 123, 255, 0.46);
  margin: 0 -16px 15px -16px;
  // margin-bottom: 15px;
  div {
    display: flex;
    justify-content: space-between;
    margin: 0 3.5rem;
    padding-top: 10px;
    p:first-child {
      font-family: Gotham;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      color: $black;
      text-align: left;
      margin-bottom: 0;
    }

    p:nth-child(2) {
      font-family: Gotham;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: $black;
      text-align: right;
      margin-bottom: 0;
    }
  }
}

.next-btn {
  background: $primary;
  border-radius: 10px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: $secondary;
  margin-bottom: 1rem;
  margin-top: 2rem;
  box-shadow: none;
}

.desclaimer__text {
  // padding-top: 4rem;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    color: $primary;
    text-align: left;
    margin-left: 2.5rem;
  }
}

.modal-foot {
  border-top: none;
}
// Firts modal steps ends

// Secosn modal steo start
.info__text {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: $black;
}

.dateOf__payment {
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    color: $black;
  }
  input {
    width: 100%;
    border-bottom: 0.5px solid #6c6c6c !important;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
}

.upload__file {
  .dropzone {
    border: 0.5px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 20px 0;
    padding: 50px 5px;
    min-height: 200px;
    cursor: pointer;
    position: relative;
    p {
      margin-bottom: 0;
      padding-top: 10px;
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 30px;
      color: $black;
    }
    .browse__span {
      color: $primary;
    }
  }
}
.dropzone:hover {
  background-color: lightgrey;
}

// .input-file {
//   opacity: 0;
//   width: 100%;
//   left: 0%;
//   height: 200px;
//   position: absolute;
//   cursor: pointer;
// }

.input-file {
  opacity: 0;
  width: 100%;
  left: 0%;
  top: 0%;
  height: 215px;
  position: absolute;
  cursor: pointer;
  z-index: 1000;
}
.actual_image {
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
  position: absolute;
  left: 0%;
  cursor: pointer;
}

.third-step {
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    // display: flex;
    // align-items: flex-end;
    text-align: center;
    color: $black;
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
}

.call-btn {
  background: #f2f5fe;
  border: 0.5px solid #6c6c6c;
  box-sizing: border-box;
  border-radius: 10px;

  // width: 154px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  box-shadow: none;
  // display: flex;
  // align-items: flex-end;
  text-align: center;
  color: $black;
  padding: 6px 26px;
  a {
    img {
      width: 27.01px;
      margin-right: 1rem;
    }
  }
}

.back__arrow {
  padding-top: 0.7rem;
  cursor: pointer;
  margin-right: 10px;
}

// Second modal step ends

// Bank transfer modal upload ends

@media only screen and (max-width: 599px) {
  .wallet_amount {
    align-items: center;
    top: 46%;
    p {
      font-size: 22px;
    }
    img {
      width: 53px;
    }
  }
  .widthdraw {
    padding: 15px 15px;
    h6 {
      font-size: 12px;
    }
  }
  .security_deposit {
    h4 {
      padding: 0px 15px;
      button {
        padding: 5px 30px;
      }
    }
  }
  .row_holder {
    div {
      margin-bottom: 10px;
    }
  }
  .transaction_holder {
    div:first-child {
      gap: 10px;
      p {
        line-height: 18px;
      }
    }
  }
  .collapsible:after {
    content: none;
  }
  .collapsible:before {
    height: 24px;
    width: 24px;
    padding-right: 8px;
    padding-top: 1px;
    padding-left: 8px;
  }
  .otp-holder {
    input {
      width: 40px;
    }
  }
  .funds_wrap {
    margin: 40px 0px;
  }
  .funds_options {
    margin: 10px 0px;
  }
  .withdrawal_text_modal {
    p {
      font-size: 11px;
    }
  }
  .transaction_holder {
    div:first-child {
      overflow: scroll;
      margin-right: 5px;
      p {
        white-space: nowrap;
        border-right: 5px solid blue;
        padding-right: 10px;
        font-size: 14px;
      }
    }
  }

  // Bank transfer
  .info__text {
    text-align: justify;
  }
  .number__holder {
    p:nth-child(2) {
      line-height: 23px;
      text-align: left;
      font-size: 13px;
    }
  }
  .sheltaAccount__details {
    div {
      margin: 5px 15px;
      p:first-child {
        font-size: 13px;
      }
      p:nth-child(2) {
        font-size: 13px;
      }
    }
  }
  .second__number {
    padding-top: 2rem;
  }
  .desclaimer__text {
    // padding-top: 1rem;
    p {
      font-size: 13px;
      line-height: 25px;
      text-align: left;
    }
  }
  .payment-header {
    font-size: 15px;
  }
  .third-step {
    p {
      font-size: 13px;
      line-height: 24px;
      text-align: left;
      padding-top: 2rem;
    }
  }
  .call-btn {
    font-size: 13px;
    padding: 6px 34px;
    a {
      img {
        margin-right: 2rem;
        width: 20.01px;
      }
    }
  }
  .third-step {
    img {
      width: 7rem;
    }
  }
  // Bank transfer
}
</style>
